import { combineReducers } from 'redux';
import types from './types';

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getSuccess: {
      const { id, onlineState } = action;
      return { ...state, [id]: onlineState };
    }
    default:
      return state;
  }
};

export default combineReducers({ byId: byIdReducer });
