import { put, call, takeEvery } from 'redux-saga/effects';
import { GetCoins } from '../services/ModerationApi';
import actions from './actions';
import types from './types';

function* getCoins({ userId }) {
  try {
    const coins = yield call(GetCoins, userId);
    yield put(actions.getCoinsSuccess(userId, coins));
  } catch (error) {
    yield put(actions.getCoinsFailure(userId, error));
  }
}

function* handleGetCoins() {
  yield takeEvery(types.getCoins, getCoins);
}

export default {
  handleGetCoins,
};
