import types from './types';

const getDialogEntry = (conversationId, userId) => ({
  type: types.getDialogEntry,
  conversationId,
  userId,
});

const getDialogEntrySuccess = (conversationId, userId, entry) => ({
  type: types.getDialogEntrySuccess,
  conversationId,
  userId,
  entry,
});
const getDialogEntryFailure = (conversationId, userId, error) => ({
  type: types.getDialogEntryFailure,
  conversationId,
  userId,
  error,
});

const updateDialogEntry = (conversationId, userId, entry) => ({
  type: types.updateEntries,
  conversationId,
  userId,
  entry,
});

const updateDialogEntrySuccess = (conversationId, userId, entry) => ({
  type: types.updateEntriesSuccess,
  conversationId,
  userId,
  entry,
});

const updateDialogEntryFailure = (conversationId, userId, error) => ({
  type: types.updateEntriesFailure,
  conversationId,
  userId,
  error,
});

export default {
  getDialogEntry,
  getDialogEntrySuccess,
  getDialogEntryFailure,
  updateDialogEntry,
  updateDialogEntrySuccess,
  updateDialogEntryFailure,
};
