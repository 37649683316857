import { formatISO } from 'date-fns';
import types from './types';

const getStatus = (toUser, fromUser) => ({
  type: types.getStatus,
  toUser,
  fromUser,
});
const getStatusSuccess = (toUser, fromUser, status, isMyRequest, createdAt) => ({
  type: types.getStatusSuccess,
  toUser,
  fromUser,
  status,
  isMyRequest,
  createdAt,
});
const getStatusFailure = (toUser, fromUser, error) => ({
  type: types.getStatusFailure,
  toUser,
  fromUser,
  error,
});
const befriend = (toUser, fromUser) => ({ type: types.befriend, toUser, fromUser });
const befriendSuccess = (toUser, fromUser) => ({ type: types.befriendSuccess, toUser, fromUser });
const befriendFailure = (toUser, fromUser, error) => ({
  type: types.befriendFailure,
  toUser,
  fromUser,
  error,
});
const accept = (toUser, fromUser) => ({ type: types.acceptRequest, toUser, fromUser });
const acceptSuccess = (toUser, fromUser) => ({
  type: types.acceptRequestSuccess,
  toUser,
  fromUser,
  createdAt: formatISO(new Date()),
});
const acceptFailure = (toUser, fromUser, error) => ({
  type: types.acceptRequestFailure,
  toUser,
  fromUser,
  error,
});
const deny = (toUser, fromUser) => ({ type: types.denyRequest, toUser, fromUser });
const denySuccess = (toUser, fromUser) => ({ type: types.denyRequestSuccess, toUser, fromUser });
const denyFailure = (toUser, fromUser, error) => ({
  type: types.denyRequestFailure,
  toUser,
  fromUser,
  error,
});

export default {
  getStatus,
  getStatusSuccess,
  getStatusFailure,
  befriend,
  befriendSuccess,
  befriendFailure,
  accept,
  acceptSuccess,
  acceptFailure,
  deny,
  denySuccess,
  denyFailure,
};
