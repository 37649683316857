import { combineReducers } from 'redux';
import types from './types';

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getCoinsSuccess: {
      const { userId, coins } = action;
      return { ...state, [userId]: coins };
    }

    default:
      return state;
  }
};

export default combineReducers({ byId: byIdReducer });
