import { combineReducers } from 'redux';
import { uniq, keyBy } from 'lodash';
import types from './types';
import galleriesTypes from '../galleries/types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case galleriesTypes.getGallerySuccess:
    case galleriesTypes.loadMoreSuccess: {
      const { images } = action;
      return uniq([...state, ...images.map(({ id }) => id)]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case galleriesTypes.getGallerySuccess:
    case galleriesTypes.loadMoreSuccess: {
      const { images } = action;
      return { ...state, ...keyBy(images, 'id') };
    }
    case types.unlockImageSuccess: {
      const { id, image } = action;
      return { ...state, [id]: { ...state[id], url: image, isUnlocked: true } };
    }
    case types.reportImageSuccess: {
      const { id } = action;
      return { ...state, [id]: { ...state[id], reported: true } };
    }
    case types.updateAdultSuccess: {
      const { id, value } = action;
      if (state[id]) {
        return { ...state, [id]: { ...state[id], adult: value } };
      }
      return state;
    }
    default:
      return state;
  }
};

const isUnlockingReducer = (state = false, action) => {
  switch (action.type) {
    case types.unlockImage:
      return true;
    case types.unlockImageSuccess:
    case types.unlockImageFailure:
      return false;
    default:
      return state;
  }
};

const isReportingReducer = (state = false, action) => {
  switch (action.type) {
    case types.reportImage:
      return true;
    case types.reportImageSuccess:
    case types.reportImageFailure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  allIds: allIdsReducer,
  byId: byIdReducer,
  isUnlocking: isUnlockingReducer,
  isReporting: isReportingReducer,
});
