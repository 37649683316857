import { put, call, takeEvery } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetOnlineStatus } from '../services/ModerationApi';

function* getOnline({ id }) {
  try {
    const { lastOnline, platform } = yield call(GetOnlineStatus, id);
    yield put(actions.getSuccess(id, { lastOnline, platform }));
  } catch (error) {
    yield put(actions.getFailure(id, error));
  }
}

function* handleGet() {
  yield takeEvery(types.get, getOnline);
}

export default {
  handleGet,
};
