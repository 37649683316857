import { combineReducers } from 'redux';
import { uniq } from 'lodash';
import types from './types';

const lastUsedEmojiReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.selectEmoji:
      return uniq([action.item, ...state]).slice(-10);
    default:
      return state;
  }
};

export default combineReducers({ lastUsedEmojis: lastUsedEmojiReducer });
