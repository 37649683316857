const updateEntries = 'site/ude/UPDATE';
const updateEntriesSuccess = 'site/ude/UPDATESUCCESS';
const updateEntriesFailure = 'site/ude/UPDATEFAILURE';
const getDialogEntry = 'site/ude/GET';
const getDialogEntrySuccess = 'site/ude/GETSUCCESS';
const getDialogEntryFailure = 'site/ude/GETFAILURE';

export default {
  updateEntries,
  updateEntriesSuccess,
  updateEntriesFailure,
  getDialogEntry,
  getDialogEntrySuccess,
  getDialogEntryFailure,
};
