import types from './types';

const show = (id, image) => ({ type: types.showGallery, id, image });
const hide = () => ({ type: types.dismissGallery });
const featureImage = image => ({ type: types.highlightImage, image });

export default {
  show,
  hide,
  featureImage,
};
