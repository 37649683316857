import { put, call, takeLatest, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import types from './types';
import { GetPresents, SendPresent } from '../services/ModerationApi';

function* getPresents() {
  try {
    const presents = yield call(GetPresents);
    yield put(actions.getPresentsSuccess(presents));
  } catch (error) {
    yield put(actions.getPresentsFailure(error));
  }
}

function* handleGetPresents() {
  yield takeLeading(types.getPresents, getPresents);
}

function* sendPresent({ id, toUser, fromUser }) {
  try {
    const { message } = yield call(SendPresent, id, fromUser, toUser);
    yield put(actions.sendPresentSuccess(message));
  } catch (error) {
    yield put(actions.sendPresentFailure(error));
  }
}

function* handleSendPresent() {
  yield takeLatest(types.sendPresent, sendPresent);
}

export default {
  handleSendPresent,
  handleGetPresents,
};
