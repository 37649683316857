import types from './types';

const getPresents = () => ({ type: types.getPresents });
const getPresentsSuccess = (presents) => ({ type: types.getPresentsSuccess, presents });
const getPresentsFailure = (error) => ({ type: types.getPresentsFailure, error });
const sendPresent = (id, toUser, fromUser) => ({
  type: types.sendPresent,
  id,
  toUser,
  fromUser,
});
const sendPresentSuccess = (message) => ({
  type: types.sendPresentSuccess,
  message,
  id: message.id,
});
const sendPresentFailure = (error) => ({ type: types.sendPresentFailure, error });

export default {
  getPresents,
  getPresentsSuccess,
  getPresentsFailure,
  sendPresent,
  sendPresentSuccess,
  sendPresentFailure,
};
