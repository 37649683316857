import { createSelector } from 'reselect';

const getOnlineStateById = (state) => state.onlineState.byId;

const getUserId = (_, { userId }) => userId;

const makeGetOnlineState = () =>
  createSelector(getOnlineStateById, getUserId, (ids, id) => ids[id]);

export default {
  makeGetOnlineState,
};
