import { put, call, takeLeading } from 'redux-saga/effects';
import { UnlockImage, ReportImage, setAdultOnImage } from '../services/ModerationApi';
import actions from './actions';
import types from './types';

function* unlock({ id, asUser }) {
  try {
    const { url } = yield call(UnlockImage, id, asUser);

    yield put(actions.unlockImageSuccess(id, url));
  } catch (error) {
    yield put(actions.unlockImageFailure(id, error));
  }
}

function* handleUnlock() {
  yield takeLeading(types.unlockImage, unlock);
}

function* reportImage({ id, reason, merits }) {
  try {
    yield call(ReportImage, id, reason, merits);
    yield put(actions.reportImageSuccess(id));
  } catch (error) {
    yield put(actions.reportImageFailure(id, error));
  }
}

function* handleReportImage() {
  yield takeLeading(types.reportImage, reportImage);
}

function* setAdult({ id, value }) {
  try {
    yield call(setAdultOnImage, id, value);
    yield put(actions.updateAdultSuccess(id, value));
  } catch (error) {
    yield put(actions.updateAdultFailure(error));
  }
}

function* handleSetAdult() {
  yield takeLeading(types.updateAdult, setAdult);
}

export default {
  handleUnlock,
  handleReportImage,
  handleSetAdult,
};
