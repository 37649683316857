import { combineReducers } from 'redux';
import { uniq, omitBy } from 'lodash';
import types from './types';
import { conversationsTypes } from '../conversations';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getDialogEntrySuccess: {
      const { conversationId, userId } = action;
      return uniq([...state, `${conversationId}-${userId}`]);
    }
    case conversationsTypes.removeConversation: {
      const { id } = state;
      return state.filter(item => item.startsWith(id));
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getDialogEntrySuccess: {
      const { conversationId, userId, entry } = action;
      return { ...state, [`${conversationId}-${userId}`]: entry };
    }
    case types.updateEntriesSuccess: {
      const { conversationId, userId, entry } = action;
      const key = `${conversationId}-${userId}`;
      return { ...state, [key]: { ...state[key], ...entry } };
    }
    case conversationsTypes.removeConversation: {
      const { id } = action;
      return omitBy(state, (_, key) => key.startsWith(id));
    }
    default:
      return state;
  }
};

export default combineReducers({ byId: byIdReducer, allIds: allIdsReducer });
