import types from './types';

const getCoins = (userId) => ({ type: types.getCoins, userId });
const getCoinsSuccess = (userId, coins) => ({ type: types.getCoinsSuccess, userId, coins });
const getCoinsFailure = (userId, error) => ({ type: types.getCoinsFailure, userId, error });

export default {
  getCoins,
  getCoinsSuccess,
  getCoinsFailure,
};
