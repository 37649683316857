import { combineReducers } from 'redux';
import { uniq } from 'lodash';
import types from './types';
import { friendShipStatus } from '../enums';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.befriendSuccess:
    case types.getStatusSuccess: {
      const { toUser, fromUser } = action;
      const id = `${toUser}_${fromUser}`;
      return uniq([...state, id]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.befriendSuccess:
    case types.denyRequestSuccess:
    case types.acceptRequestSuccess:
    case types.getStatusSuccess: {
      const { fromUser, toUser } = action;
      const id = `${toUser}_${fromUser}`;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    default:
      return state;
  }
};

const valueReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getStatusSuccess: {
      const { status, isMyRequest, createdAt } = action;
      return {
        ...state,
        status,
        isMyRequest,
        createdAt,
      };
    }
    case types.befriendSuccess: {
      return { ...state, isMyRequest: true, status: friendShipStatus.pending };
    }
    case types.acceptRequestSuccess: {
      const { createdAt } = action;
      return { ...state, status: friendShipStatus.accepted, createdAt };
    }
    case types.denyRequestSuccess: {
      return {
        ...state,
        status: state.isMyRequest ? friendShipStatus.noRequest : friendShipStatus.denied,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ byId: byIdReducer, allIds: allIdsReducer });
