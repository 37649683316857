import { createSelector } from 'reselect';

const getFriendsShipsById = (state) => state.friendships.byId;

const getId = (_, { toUser, fromUser }) => `${toUser}_${fromUser}`;

const makeGetFriendShip = () =>
  createSelector(getFriendsShipsById, getId, (byId, id) => byId[id] && { ...byId[id] });

export default {
  makeGetFriendShip,
};
