const unlockImage = 'site/images/UNLOCK';
const unlockImageSuccess = 'site/images/UNLOCKSUCCESS';
const unlockImageFailure = 'site/images/UNLOCKFAILURE';
const reportImage = 'site/images/REPORT';
const reportImageSuccess = 'site/images/REPORTSUCCESS';
const reportImageFailure = 'site/images/REPORTFAILURE';
const updateAdult = 'site/images/UPDATEADULT';
const updateAdultSuccess = 'site/images/UPDATEADULTSUCCESS';
const updateAdultFailure = 'site/images/UPDATEADULTFAILURE';

export default {
  unlockImage,
  unlockImageSuccess,
  unlockImageFailure,
  reportImage,
  reportImageSuccess,
  reportImageFailure,
  updateAdult,
  updateAdultSuccess,
  updateAdultFailure,
};
