import types from './types';

const get = id => ({ type: types.get, id });
const getSuccess = (id, onlineState) => ({ type: types.getSuccess, id, onlineState });
const getFailure = (id, error) => ({ type: types.getSuccess, id, error });

export default {
  get,
  getSuccess,
  getFailure,
};
