import {
  put, call, fork, take, takeEvery, cancel,
} from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetUserDialogInfo, UpdateUserDialogInfo } from '../services/ModerationApi';

function* getUserDialogInfo({ conversationId, userId }) {
  try {
    const entry = yield call(GetUserDialogInfo, conversationId, userId);
    yield put(actions.getDialogEntrySuccess(conversationId, userId, entry));
  } catch (error) {
    yield put(actions.getDialogEntryFailure(conversationId, userId, error));
  }
}

function* handleGetUserDialogInfo() {
  yield takeEvery(types.getDialogEntry, getUserDialogInfo);
}

function* updateDialogEntry({ userId, conversationId, entry }) {
  try {
    yield call(UpdateUserDialogInfo, conversationId, userId, entry);
    yield put(actions.updateDialogEntrySuccess(conversationId, userId, entry));
  } catch (error) {
    yield put(actions.updateDialogEntryFailure(conversationId, userId, error));
  }
}

function* handleUpdateDialogEntry() {
  const openedRequests = {};
  while (true) {
    const action = yield take(types.updateEntries);
    const { userId, conversationId, entry } = action;
    const key = [userId, conversationId, ...Object.keys(entry)].join('-');
    if (openedRequests[key]) {
      yield cancel(openedRequests[key]);
    }
    openedRequests[key] = yield fork(updateDialogEntry, action);
  }
}

export default { handleGetUserDialogInfo, handleUpdateDialogEntry };
