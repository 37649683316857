import { createSelector } from 'reselect';
import { omit } from 'lodash';

const getUserDialogEntriesById = ({ userDialogEntries: { byId } }) => byId;

const getUserId = (_, { userId }) => userId;

const getConversationId = (_, { conversationId }) => conversationId;

const makeGetUserDialogEntry = () =>
  createSelector(
    getUserDialogEntriesById,
    getUserId,
    getConversationId,
    (entries, userId, conversationId) =>
      entries[`${conversationId}-${userId}`] &&
      omit(entries[`${conversationId}-${userId}`], 'freetext')
  );

const makeGetUserInformation = () =>
  createSelector(
    getUserDialogEntriesById,
    getUserId,
    getConversationId,
    (entries, userId, conversationId) =>
      entries[`${conversationId}-${userId}`]
        ? entries[`${conversationId}-${userId}`].freetext || ''
        : null
  );

export default { makeGetUserDialogEntry, makeGetUserInformation };
