import { createSelector } from 'reselect';

const getCoinsById = (state) => state.coins.byId;

const getUserId = (_, { userId }) => userId;

const makeGetUserCoins = () =>
  createSelector(getCoinsById, getUserId, (coins, userId) => coins[userId]);

export default {
  makeGetUserCoins,
};
