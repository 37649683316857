import types from './types';

const unlockImage = (id, asUser) => ({ type: types.unlockImage, id, asUser });
const unlockImageSuccess = (id, image) => ({ type: types.unlockImageSuccess, id, image });
const unlockImageFailure = (id, error) => ({ type: types.unlockImageFailure, id, error });
const reportImage = (id, reason, merits) => ({
  type: types.reportImage,
  id,
  reason,
  merits,
});
const reportImageSuccess = id => ({
  type: types.reportImageSuccess,
  id,
});
const reportImageFailure = (id, error) => ({
  type: types.reportImageFailure,
  id,
  error,
});

const updateAdult = (id, value) => ({ type: types.updateAdult, id, value });
const updateAdultSuccess = (id, value) => ({ type: types.updateAdultSuccess, id, value });
const updateAdultFailure = error => ({ type: types.updateAdultFailure, error });

export default {
  unlockImage,
  unlockImageSuccess,
  unlockImageFailure,
  reportImage,
  reportImageSuccess,
  reportImageFailure,
  updateAdult,
  updateAdultSuccess,
  updateAdultFailure,
};
