import types from './types';

const sendIceBreaker = (conversationId) => ({
  type: types.sendIceBreaker,
  conversationId,
});

const sendIceBreakerSuccess = (message) => ({
  type: types.sendIceBreakerSuccess,
  message,
  id: message.id,
});

const sendIceBreakerFailure = (error) => ({
  type: types.sendIceBreakerFailure,
  error,
});

const addIncomingMessage = (message) => ({
  type: types.addIncomingMessage,
  message,
});

const setPlay = (id, isPlayed) => ({ type: types.setPlay, id, isPlayed });

const reportAgent = (agentAlias, id, conversationId, reason, flags) => ({
  type: types.reportAgent,
  agentAlias,
  id,
  conversationId,
  reason,
  flags,
});

const reportAgentSuccess = (agentAlias, id) => ({
  type: types.reportAgentSuccess,
  agentAlias,
  id,
});

const reportAgentFailure = (error) => ({
  type: types.reportAgentFailure,
  error,
});

export default {
  sendIceBreaker,
  sendIceBreakerSuccess,
  sendIceBreakerFailure,
  addIncomingMessage,
  setPlay,
  reportAgent,
  reportAgentSuccess,
  reportAgentFailure,
};
