import { createSelector } from 'reselect';
import { imagesSelectors } from '../images';

const getGalleriesById = state => state.galleries.byId;

const getGalleryId = (_state, { id }) => id;

const getGallery = createSelector(getGalleriesById, getGalleryId, (byId, id) => byId[id]);

const makeGetImages = () =>
  createSelector(getGallery, imagesSelectors.getImagesById, (gallery, images) =>
    gallery ? gallery.images.map(id => images[id]) : []
  );

const makeGetLastUser = () =>
  createSelector(getGallery, gallery => (gallery ? gallery.lastUser : null));

const makeGetImageCount = () =>
  createSelector(getGallery, gallery => (gallery ? gallery.imageCount : 0));

export default {
  getGalleriesById,
  makeGetImages,
  makeGetImageCount,
  makeGetLastUser,
};
