import { call, put, takeEvery } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetGallery } from '../services/ModerationApi';

function* getGallery({ id, asUser, isModerated }) {
  try {
    const { count, gallery } = yield call(GetGallery, id, asUser);
    yield put(actions.getSuccess(id, gallery, count, asUser, isModerated));
  } catch (error) {
    yield put(actions.getFailure(id, error));
  }
}

function* handleGetGallery() {
  yield takeEvery(types.getGallery, getGallery);
}

export default { handleGetGallery };
