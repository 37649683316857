import { combineReducers } from 'redux';
import types from './types';
import { reservedConversationTypes } from '../reservedConversation';

const imageReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.showGallery:
    case types.highlightImage:
      return action.image || 0;
    case reservedConversationTypes.reserveConversationSuccess:
    case types.dismissGallery:
      return null;
    default:
      return state;
  }
};

const galleryIdReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.showGallery:
      return action.id;
    case reservedConversationTypes.reserveConversationSuccess:
    case types.dismissGallery:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ galleryId: galleryIdReducer, image: imageReducer });
