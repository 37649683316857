import { uniq } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getGallerySuccess:
      return uniq([...state, action.id]);

    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallerySuccess: {
      const { id, images, imageCount, asUser, isModerated } = action;
      return {
        ...state,
        [id]: {
          images: uniq(images.map(({ id: imageId }) => imageId)),
          nextPage: 2,
          imageCount,
          lastUser: asUser,
          isModerated,
        },
      };
    }
    case types.loadMoreSuccess: {
      const { id } = action;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    default:
      return state;
  }
};

const valueReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.loadMoreSuccess: {
      const { images, moreRecords } = action;
      return {
        ...state,
        images: uniq([...state.images, ...images.map(({ id }) => id)]),
        nextPage: state.nextPage + 1,
        moreRecords,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ allIds: allIdsReducer, byId: byIdReducer });
