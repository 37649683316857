import { uniq, keyBy, mapValues } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import reservedConversationTypes from '../reservedConversation/types';
import { conversationsTypes } from '../conversations';
import { presentsTypes } from '../presents';
import { websocketTypes } from '../websocket';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case conversationsTypes.getConversationSuccess:
    case conversationsTypes.loadMoreSuccess: {
      const { messages } = action;
      return uniq([...state, ...messages.map(({ id }) => id)]);
    }
    case types.sendIceBreakerSuccess:
    case presentsTypes.sendPresentSuccess:
    case reservedConversationTypes.holdMessageSuccess:
    case reservedConversationTypes.sendMessageSuccess: {
      const { id } = action;
      return uniq([...state, id]);
    }
    case websocketTypes.incoming: {
      const {
        message: { id },
      } = action;
      return uniq([...state, id]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case conversationsTypes.loadMoreSuccess:
    case conversationsTypes.getConversationSuccess: {
      const { messages } = action;
      return {
        ...state,
        ...keyBy(
          messages.map((item) => ({ ...item, isPlayed: false })),
          'id'
        ),
      };
    }
    case presentsTypes.sendPresentSuccess:
    case types.sendIceBreakerSuccess:
    case reservedConversationTypes.holdMessageSuccess:
    case reservedConversationTypes.sendMessageSuccess: {
      const { id, message } = action;
      return { ...state, [id]: message };
    }
    case websocketTypes.incoming: {
      const { message } = action;
      const { id } = message;
      return { ...state, [id]: message };
    }
    case types.setPlay: {
      const { id, isPlayed } = action;
      return mapValues((val) => ({
        ...val,
        isPlayed: val.id === id ? isPlayed : false,
      }));
    }
    default:
      return state;
  }
};

const isReportingReducer = (state = false, action) => {
  switch (action.type) {
    case types.reportAgent:
      return true;
    case types.reportAgentSuccess:
    case types.reportAgentFailure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId: byIdReducer,
  allIds: allIdsReducer,
  isReporting: isReportingReducer,
});
