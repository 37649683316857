const getGallery = 'site/galleries/GET';
const getGallerySuccess = 'site/galleries/GETSUCCESS';
const getGalleryFailure = 'site/galleries/GETFAILURE';
const loadMore = 'site/galleries/LOADMORE';
const loadMoreSuccess = 'site/galleries/LOADMORESUCCESS';
const loadMoreFailure = 'site/galleries/LOADMOREFAILURE';

export default {
  getGallery,
  getGallerySuccess,
  getGalleryFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
};
