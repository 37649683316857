const getStatus = 'site/friendships/GETSTATUS';
const getStatusSuccess = 'site/friendships/GETSTATUSSUCCESS';
const getStatusFailure = 'site/friendships/GETSTATUSFAILURE';
const befriend = 'site/friendships/BEFRIEND';
const befriendSuccess = 'site/friendships/BEFRIENDSUCCESS';
const befriendFailure = 'site/friendships/BEFRIENDFAILURE';
const acceptRequest = 'site/friendships/ACCEPT';
const acceptRequestSuccess = 'site/friendships/ACCEPTSUCCESS';
const acceptRequestFailure = 'site/friendships/ACCEPTFAILURE';
const denyRequest = 'site/friendships/DENY';
const denyRequestSuccess = 'site/friendships/DENYSUCCESS';
const denyRequestFailure = 'site/friendships/DENYFAILURE';

export default {
  getStatus,
  getStatusSuccess,
  getStatusFailure,
  befriend,
  befriendSuccess,
  befriendFailure,
  acceptRequest,
  acceptRequestSuccess,
  acceptRequestFailure,
  denyRequest,
  denyRequestSuccess,
  denyRequestFailure,
};
