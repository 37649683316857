import {
  put, call, takeEvery, takeLeading,
} from 'redux-saga/effects';
import types from './types';
import {
  DenyFriendRequest,
  AcceptFriendRequest,
  SendFriendRequest,
  GetFriendshipStatus,
} from '../services/ModerationApi';
import actions from './actions';

function* getStatus({ toUser, fromUser }) {
  try {
    const { status, isMyRequest, createdAt } = yield call(GetFriendshipStatus, toUser, fromUser);
    yield put(actions.getStatusSuccess(toUser, fromUser, status, isMyRequest, createdAt));
  } catch (error) {
    yield put(actions.getStatusFailure(toUser, fromUser, error));
  }
}

function* handleGetStatus() {
  yield takeEvery(types.getStatus, getStatus);
}

function* befriend({ toUser, fromUser }) {
  try {
    yield call(SendFriendRequest, toUser, fromUser);
    yield put(actions.befriendSuccess(toUser, fromUser));
  } catch (error) {
    yield put(actions.befriendFailure(toUser, fromUser, error));
  }
}

function* handleBefriend() {
  yield takeLeading(types.befriend, befriend);
}

function* acceptRequest({ toUser, fromUser }) {
  try {
    yield call(AcceptFriendRequest, toUser, fromUser);
    yield put(actions.acceptSuccess(toUser, fromUser));
  } catch (error) {
    yield put(actions.acceptFailure(toUser, fromUser, error));
  }
}

function* handleAccept() {
  yield takeLeading(types.acceptRequest, acceptRequest);
}

function* denyRequest({ toUser, fromUser }) {
  try {
    yield call(DenyFriendRequest, toUser, fromUser);
    yield put(actions.denySuccess(toUser, fromUser));
  } catch (error) {
    yield put(actions.denyFailure(toUser, fromUser, error));
  }
}

function* handleDeny() {
  yield takeLeading(types.denyRequest, denyRequest);
}

export default {
  handleAccept,
  handleBefriend,
  handleDeny,
  handleGetStatus,
};
