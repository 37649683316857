import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CreateMessage, reportAgentForMessage } from '../services/ModerationApi';
import actions from './actions';
import types from './types';

function* sendIceBreaker({ conversationId }) {
  try {
    const { message } = yield call(CreateMessage, {
      conversationId,
      hold: true,
      messageType: 'IceBreaker',
      message: 'IceBreaker',
    });
    yield put(actions.sendIceBreakerSuccess(message));
  } catch (error) {
    yield put(actions.sendIceBreakerFailure(error));
  }
}

function* handleSendIceBreaker() {
  yield takeEvery(types.sendIceBreaker, sendIceBreaker);
}

function* reportAgent({ agentAlias, id, conversationId, reason, flags }) {
  try {
    yield call(reportAgentForMessage, agentAlias, id, conversationId, reason, flags);
    yield put(actions.reportAgentSuccess(agentAlias, id));
  } catch (error) {
    yield put(actions.reportAgentFailure(error));
  }
}

function* handleReportAgent() {
  yield takeLatest(types.reportAgent, reportAgent);
}

export default {
  handleSendIceBreaker,
  handleReportAgent,
};
