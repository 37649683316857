import types from './types';

const presentReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getPresentsSuccess:
      return action.presents;
    default:
      return state;
  }
};

export default presentReducer;
