import types from './types';

const get = (id, asUser, isModerated) => ({ type: types.getGallery, id, asUser, isModerated });
const getSuccess = (id, images, imageCount, asUser, isModerated) => ({
  type: types.getGallerySuccess,
  id,
  images,
  imageCount,
  asUser,
  isModerated,
});
const getFailure = (id, error) => ({ type: types.getGalleryFailure, id, error });
const loadMore = (id, page) => ({ type: types.loadMore, id, page });
const loadMoreSuccess = (id, images) => ({ type: types.loadMoreSuccess, id, images });
const loadMoreFailure = (id, error) => ({ type: types.loadMoreFailure, id, error });

export default {
  get,
  getSuccess,
  getFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
};
