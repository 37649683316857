import { createSelector } from 'reselect';
import { galleriesSelectors } from '../galleries';
import { imagesSelectors } from '../images';

const getFeaturedGalleryId = state => state.featuredGallery.galleryId;

const getFeaturedImageId = ({ featuredGallery: { image } }) => image;

const getFeaturedGallery = createSelector(
  galleriesSelectors.getGalleriesById,
  getFeaturedGalleryId,
  imagesSelectors.getImagesById,
  (byId, id, images) => ((id || id === 0) && byId[id] ? byId[id].images.map(id => images[id]) : [])
);

const getGalleryInfo = createSelector(
  galleriesSelectors.getGalleriesById,
  getFeaturedGalleryId,
  (byId, id) => byId[id]
);

export default { getFeaturedGallery, getFeaturedImageId, getFeaturedGalleryId, getGalleryInfo };
